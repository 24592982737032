// Configuration
const headers = {
    'Content-Type': 'application/json'
};

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

// Utility functions
const request = (url) => {
    return (method) => {
        return async (endpoint, auth, payload) => {

            const config = {
                method: method,
                headers: headers,
            };

            if (!!auth) {
                config.headers['Authorization'] = auth;
            }

            if (payload != null) {
                config.body = JSON.stringify(payload);
            }

            let responseBody;
            const response = await fetch(`${url}/${endpoint}`, config);

            try {
                responseBody = await response.json();
                return { status: response.status, response: responseBody };
            }
            catch {
                return { status: response.status, response: null };
            }
        }
    }
};

// const api = request('http://localhost:8080/api');
const api = request('https://api.mp.chokk.net/api');

export const post = api('POST');
export const get = api('GET');
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { queryPayment, capturePayment, cancelPayment } from '../services/payment.service';
import Spinner from './Spinner';
import Success from './Success';
import Failure from './Failure';
import useToken from './../hooks/useToken';

function Landing() {

    const { token, _ } = useToken();

    const [data, setPayment] = useState(null);
    const [retries, setRetries] = useState(1);

    let [searchParams] = useSearchParams();
    const reference = searchParams.get("reference");

    useEffect(() => {
        let timerId;

        async function handleReserved(payment) {
            if (await capturePayment(payment.paymentId, payment.amount, token)) {
                setPayment(payment);
            }
        }

        async function handleInitiated(payment, numRetries) {
            if (numRetries > 10) {
                await cancelPayment(payment.paymentId, token)
            }
        }

        const query = async () => {
            const payment = await queryPayment(reference, token);
            if (payment == null) {
                return;
            }
            switch (payment.state) {
                case 'reserved': {
                    await handleReserved(payment);
                    break;
                }
                case 'initiated': {
                    await handleInitiated(payment, retries);
                    break;
                }
                default: {
                    setPayment(payment);
                    return;
                }
            }
            timerId = setTimeout(() => {
                setRetries(retries + 1);
            }, 1000);
        }

        query();

        return () => { 
            clearTimeout(timerId);
        }
    }, [retries, reference]);

    function getComponent(payment) {
        if (!payment) {
            return <Spinner />
        }
        switch (payment.state) {
            case 'captured':
                return <Success />
            case 'cancelledByMerchant':
            case 'cancelledByUser':
            case 'cancelledBySystem':
                return <Failure />
            default:
                return <Spinner />
        }
    }

    return (
        <div>
            {getComponent(data)}
        </div>
    );
}

export default Landing;
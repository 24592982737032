import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Home.css';
import printer from '../assets/printer.png';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

function Home() {
    return (
        <Box sx={{ width: '100%', maxWidth: 340, bgcolor: 'background.paper', p: 1 }}>
            <Box
                maxWidth={true}
                component="img"
                sx={{
                }}
                src={printer}
            />
            <Box sx={{ my: 3, mx: 2 }}>
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Typography gutterBottom variant="h4" component="div">
                            Printer
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom variant="h6" component="div">
                            DKK 49,-
                        </Typography>
                    </Grid>
                </Grid>
                <Typography color="text.secondary" variant="body2">
                    Efficient, high-resolution, wireless, multi-function—ideal for any printing need.
                </Typography>
            </Box>
            <Divider variant="middle" />
            <Box sx={{ m: 2 }}>
                <Typography gutterBottom variant="body1">
                    Select color
                </Typography>
                <Stack direction="row" spacing={1}>
                    <Chip label="White" />
                    <Chip color="primary" label="Black" />
                    <Chip label="Red" />
                    <Chip label="Blue" />
                </Stack>
            </Box>
            <Box sx={{ mt: 3, ml: 1, mb: 1 }}>
                <Button component={Link} to='checkout'>Purchase</Button>
            </Box>
        </Box>
    );
}

export default Home;

import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

function Failure() {

    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', p: 1 }}>
            <Box sx={{ my: 3, mx: 2 }}>
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Typography gutterBottom variant="h4" component="div">
                            Payment failed.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Typography color="text.secondary" variant="body2">
                            We were unable to process the payment.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Divider variant="middle" />
            <Box sx={{ minWidth: 500 }}>
            </Box>
            <Box sx={{ mt: 3, ml: 1, mb: 1 }}>
                <Button component={Link} to='/'>To front page</Button>
            </Box>
        </Box>
    );
}

export default Failure;

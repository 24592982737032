import { get, post } from './http';

export const initiatePayment = async (phone, auth) => {
  const { status, response: { mobilePayAppRedirectUri: url } } = await post('initiate', auth, { amount: 500, phone });
  return { status, url };
};

export const capturePayment = async (paymentId, amount, auth) => {
  const { status } = await post(`${paymentId}/capture`, auth, { amount });
  return status == 204;
};

export const cancelPayment = async (paymentId, auth) => {
  const { status } = await post(`${paymentId}/cancel`, auth);
  return status == 204;
};

export const queryPayment = async (reference, auth) => {
  const { response } = await get(`query/${reference}`, auth);
  return response;
};
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div id="logo">
      <Typography gutterBottom variant="h4" component="div" color="common.white">
        MERCHANT WEBSITE
      </Typography>
    </div>
    <div id="user">
      {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
      <Grid container>
        <Grid item xs={12}>
          <Typography color="white" variant="body1">
            Logged in as <b>Mr. Test</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color="white" variant="body1">
            Phone: (+45) 20 03 18 05
          </Typography>
        </Grid>
      </Grid>
    </div>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

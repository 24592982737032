import React from 'react';
import '../styles/Spinner.css';  // Import the CSS file for styling

function Spinner() {
  return (
    <div className="spinner-container">
      <svg className="spinner" viewBox="0 0 50 50">
        <circle className="spinner-path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
      </svg>
    </div>
  );
}

export default Spinner;
import React, { useState } from 'react';
import { authenticate } from './../services/auth.service';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';

export default function Login({ setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = await authenticate(username, password);
        setToken(token);
    }

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ width: '100%', maxWidth: 340, bgcolor: 'background.paper', p: 1 }}>
                <Grid container alignItems="center">
                    <Grid item xs={12} p={1}>
                        <TextField sx={{ width: '100%' }}
                            required
                            label="Username"
                            onChange={e => setUserName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} p={1}>
                        <TextField sx={{ width: '100%' }}
                            required
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            onChange={e => setPassword(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} p={1}>
                        <Button type='submit'>Login</Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}
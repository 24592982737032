import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { initiatePayment } from '../services/payment.service';
import Spinner from './Spinner';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import mp from '../assets/mp.png';
import useToken from './../hooks/useToken';

function Checkout() {

    const { token, _ } = useToken();

    let [data, setData] = useState({
        showSpinner: false,
        numRetries: 0,
        textField: null
    });

    async function createPayment(phone) {
        const { status, url } = await initiatePayment(phone, token);
        if (status != 200 && data.numRetries < 3) {
            setData({ ...data, numRetries: data.numRetries += 1 })
            return setTimeout(createPayment, 1000);
        }
        window.location.href = url;
    }

    const clickHandler = async () => {
        setData({ ...data, showSpinner: true });
    }

    const changeHandler = async (e) => {
        setData({ ...data, textField: e.target.value });
    }

    useEffect(() => {
        if (data.showSpinner) {
            createPayment('+4520031805');
        }

        return () => { };

    }, [data.showSpinner, data.numRetries, data.textField])

    return (
        <div>
            {data.showSpinner ? (
                <div>
                    <Spinner />
                </div>
            ) : (
                <Box sx={{ width: '100%', maxWidth: 340, bgcolor: 'background.paper', p: 1 }}>
                    <Box sx={{ my: 3, mx: 2, mb: 1 }}>
                        <Grid container alignItems="center">
                            <Grid item xs>
                                <Typography gutterBottom variant="h4" component="div">
                                    Basket
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center">
                            <Grid item xs>
                                <Typography color="text.secondary" variant="body2">
                                    1 x Canon Printer (Black)
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography gutterBottom variant="body1" component="div" align='right'>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center">
                            <Grid item xs>
                                <Typography color="text.secondary" variant="body2" fontWeight={'bold'}>
                                    Total
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography gutterBottom variant="body1" component="div" align='right'>
                                    DKK 49,-
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider variant="middle" />
                    <Box sx={{ minWidth: 350, mt: 1, ml: 0, mb: 0, textAlign: 'center' }}>
                        <Link onClick={clickHandler}>
                            <Box
                                component="img"
                                sx={{
                                }}
                                src={mp}
                            />
                        </Link>
                    </Box>
                </Box>
            )}
        </div>
    );
}

export default Checkout;
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Checkout from './components/Checkout';
import Landing from './components/Landing';
import Success from './components/Success';
import Login from './components/Login';
import useToken from './hooks/useToken'
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {

  const { token, setToken } = useToken();

  if (!token) {
    return (
      <div className='container'>
        <Login setToken={setToken} />
      </div>
      )
  }
  return (
    <div className='container'>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/success" element={<Success />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

import { post } from './http';

export const authenticate = async (username, password) => {
  const { status, response } = await post('authenticate', null, { username, password });
  if (status === 200) {
    return response.token;
  }
};

export const setSessionToken = (callback) => {
  return (token) => {
    if (!!token) {
      localStorage.setItem('token', token);
      callback(token);
    }
  };
}

export const getSessionToken = () => localStorage.getItem('token');